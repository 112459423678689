<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0">
    <!-- SMTP Server -->
    <smtp-server />
    <media-server />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <numberItemPerPage />
  </v-container>
</template>

<script>
import smtpServer from '../AgentServer/SMTPServer'
import mediaServer from '../AgentServer/MediaServer'
import numberItemPerPage from './NumberItemPerPage'
import { mapGetters } from 'vuex'
export default {
  components: {
    smtpServer,
    mediaServer,
    numberItemPerPage
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['GET_SNACK_BAR'])
  },
  watch: {},
  created () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>

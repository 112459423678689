<template>
  <v-layout wrap>
    <v-flex md12>
      <material-card
        :title="$t('settings.numberItemOfPage.title')"
        color="green"
        flat
        full-width
        class="bg-white"
      >
        <v-expansion-panel popout>
          <v-expansion-panel-content
            v-for="(item, i) in 3"
            :key="i">
            <template v-slot:header>
              <div v-if="item == 1">
                {{ $t("settings.numberItemOfPage.systemNumberItemOfPage") }}
              </div>
              <div v-else-if="item == 2">
                {{ $t("settings.numberItemOfPage.userNumberItemOfPage") }}
              </div>
            </template>
            <template>
              <div v-if="item == 1">
                <v-container
                  v-if="isSuperAdmin()"
                  grid-list-xl
                >
                  <v-form>
                    <v-layout
                      wrap
                      layout>
                      <v-flex
                        xs12
                        sm6
                        md6>
                        <v-text-field
                          :rules="[requiredValue]"
                          v-model="sysNumberItemOfPage"
                          :label="$t('settings.numberItemOfPage.value')"
                          type="number"
                          class="required"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer />
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="saveSystemNumberItemOfPage"
                  >{{ $t("common.save") }}</v-btn
                  >
                </v-container>
              </div>
              <div v-else-if="item == 2">
                <v-container grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout>
                      <v-flex
                        xs12
                        sm6
                        md6>
                        <v-text-field
                          v-model="userNumberItemOfPage"
                          :label="$t('settings.numberItemOfPage.value')"
                          type="number"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer />
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="saveUserNumberItemOfPage"
                  >{{ $t("common.save") }}</v-btn
                  >
                </v-container>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </material-card>
    </v-flex>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <loading-bar :is-loading="isLoading" />
  </v-layout>
</template>

<script>
import functionUtils from 'utils/functionUtils'
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import RoleType from 'enum/roleType'
export default {
  data: () => ({
    valid: false,
    isBtnLoading: false,
    isLoading: false,
    sysNumberItemOfPage: 15,
    userNumberItemOfPage: 15,
    maxNumberItemOfPage: 200
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {
    GET_CURRENT_ACCOUNT: function () {}
  },
  created () {
    this.isLoading = true
    this.GET_SYSTEM_NUMBER_ITEM_OF_PAGE()
      .then(
        function (res) {
          let data = res.data
          this.sysNumberItemOfPage = data.number_item_of_page
          this.isLoading = false
        }.bind(this)
      )
      .catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    //
    this.GET_USER_NUMBER_ITEM_OF_PAGE()
      .then(
        function (res) {
          let data = res.data
          this.userNumberItemOfPage = data.number_item_of_page
          this.isLoading = false
        }.bind(this)
      )
      .catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
  },
  methods: {
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(
        RoleType.ADMIN,
        this.GET_CURRENT_ACCOUNT
      )
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(
        RoleType.MODERATOR,
        this.GET_CURRENT_ACCOUNT
      )
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(
        RoleType.ACCOUNTANT,
        this.GET_CURRENT_ACCOUNT
      )
    },
    /**
     * Required value
     */
    requiredValue (value) {
      if (!functionUtils.isNull(value) && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    isGreaterThanZero (value) {
      value = functionUtils.formatInteger(value)
      if (value > 0) {
        return true
      }
      return false
    },
    saveSystemNumberItemOfPage: function () {
      if (functionUtils.validateStringIsNull(this.sysNumberItemOfPage)) {
        this.ON_SHOW_TOAST({
          message: this.$t('settings.numberItemOfPage.value'),
          styleType: ToastType.ERROR
        })
      } else if (!this.isGreaterThanZero(this.sysNumberItemOfPage)) {
        this.ON_SHOW_TOAST({
          message: this.$t('users.valueMustBeGreaterThanZero'),
          styleType: ToastType.ERROR
        })
      } else {
        let filter = {
          number_item_of_page: this.sysNumberItemOfPage
        }
        this.isBtnLoading = true
        this.UPDATE_SYSTEM_NUMBER_ITEM_OF_PAGE(filter)
          .then(
            function () {
              this.isBtnLoading = false
              this.ON_SHOW_TOAST({
                message: this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
            }.bind(this)
          )
          .catch(
            function () {
              this.isBtnLoading = false
              this.ON_SHOW_TOAST({
                message: this.$t('common.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }.bind(this)
          )
      }
    },
    saveUserNumberItemOfPage: function () {
      if (!this.isGreaterThanZero(this.userNumberItemOfPage) && !functionUtils.validateStringIsNull(this.userNumberItemOfPage)) {
        this.ON_SHOW_TOAST({
          message: this.$t('users.valueMustBeGreaterThanZero'),
          styleType: ToastType.ERROR
        })
      } else {
        let filter = {
          number_item_of_page: !functionUtils.validateStringIsNull(this.userNumberItemOfPage)
            ? this.userNumberItemOfPage : null
        }
        this.isBtnLoading = true
        this.UPDATE_USER_NUMBER_ITEM_OF_PAGE(filter)
          .then(
            function () {
              this.isBtnLoading = false
              this.ON_SHOW_TOAST({
                message: this.$t('common.success'),
                styleType: ToastType.SUCCESS
              })
            }.bind(this)
          )
          .catch(
            function () {
              this.isBtnLoading = false
              this.ON_SHOW_TOAST({
                message: this.$t('common.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }.bind(this)
          )
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_SYSTEM_NUMBER_ITEM_OF_PAGE',
      'UPDATE_SYSTEM_NUMBER_ITEM_OF_PAGE',
      'GET_USER_NUMBER_ITEM_OF_PAGE',
      'UPDATE_USER_NUMBER_ITEM_OF_PAGE'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>

<template>
  <v-layout
    v-if="isSuperAdmin() || hasSystemRole(RoleType.ADMIN)"
    wrap>
    <v-flex md12>
      <material-card
        :title="'Media'"
        color="green"
        flat
        full-width
      >
        <v-expansion-panel popout>
          <v-expansion-panel-content
            v-for="(item, i) in 3"
            :key="i"
          >
            <template v-slot:header>
              <div v-if="item == 1">
                {{ $t('generalConfiguration.serverMedia') }}
              </div>
              <div v-else-if="item == 2">
                {{ $t('generalConfiguration.mediaProject') }}
              </div>
            </template>
            <template>
              <div v-if="item == 1">
                <v-container
                  v-if="isSuperAdmin()"
                  grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout
                    >
                      <v-flex
                        xs12
                        sm12
                        md12
                      >
                        <v-text-field
                          :rules="[requiredValue]"
                          v-model="mediaServer.mediaDomain"
                          :label="$t('generalConfiguration.mediaDomain')"
                          class="required"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          :rules="[requiredValue]"
                          v-model="mediaServer.mediaUsername"
                          :label="$t('generalConfiguration.mediaUsername')"
                          class="required"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          :rules="[requiredValue]"
                          v-model="mediaServer.mediaPassword"
                          :label="$t('generalConfiguration.mediaPassword')"
                          type="password"
                          class="required"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer/>
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="saveConfigSMTP"
                  >{{ $t('common.save') }}</v-btn>
                </v-container>
              </div>
              <div v-else-if="item == 2">
                <v-container grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout
                    >
                      <v-flex
                        v-if="isSuperAdmin()"
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="mediaProject.mediaProjectName"
                          :label="$t('generalConfiguration.mediaProjectName')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="mediaProject.mediaPageSize"
                          :label="$t('generalConfiguration.mediaPageSize')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm12
                        md12
                      >
                        <v-textarea
                          v-model="mediaProject.mediaDescription"
                          :label="$t('generalConfiguration.mediaDescription')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer/>
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="saveSMTPProject"
                  >{{ $t('common.save') }}</v-btn>
                </v-container>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </material-card>
    </v-flex>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import RoleType from 'enum/roleType'
import ToastType from '@/enum/toastType'
export default {
  components: {
  },
  data: () => ({
    valid: false,
    mediaProject: {
      mediaProjectName: null,
      mediaPageSize: null,
      mediaDescription: null
    },
    mediaServer: {
      mediaDomain: null,
      mediaUsername: null,
      mediaPassword: null
    },
    RoleType: {
      ADMIN: RoleType.ADMIN
    },
    entity: {},
    allProjects: []
  }),
  computed: {
    ...mapGetters([
      'GET_MEDIA_CONFIG_DATA',
      'GET_MEDIA_PROJECT_DATA',
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    GET_MEDIA_CONFIG_DATA: function () {
      this.mediaServer.mediaDomain = this.GET_MEDIA_CONFIG_DATA.media_domain ? this.GET_MEDIA_CONFIG_DATA.media_domain : ''
      this.mediaServer.mediaUsername = this.GET_MEDIA_CONFIG_DATA.media_username && this.GET_MEDIA_CONFIG_DATA.media_username
      this.mediaServer.mediaPassword = ''
    },
    GET_MEDIA_PROJECT_DATA: function () {
      this.mediaProject.mediaProjectName = this.GET_MEDIA_PROJECT_DATA.name && this.GET_MEDIA_PROJECT_DATA.name
      this.mediaProject.mediaPageSize = this.GET_MEDIA_PROJECT_DATA.pagination && this.GET_MEDIA_PROJECT_DATA.pagination
      this.mediaProject.mediaDescription = this.GET_MEDIA_PROJECT_DATA.description && this.GET_MEDIA_PROJECT_DATA.description
    }
  },
  created () {
    this.GET_MEDIA_CONFIG()
    this.getMediaProject()
  },
  methods: {
    // Required value
    requiredValue (value) {
      if (!functionUtils.isNull(value) && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    /**
     * Is entity type
     */
    isEntityType: function (entityType) {
      return functionUtils.isEntityType(entityType)
    },
    /**
     * Has system role
     */
    hasSystemRole: function (roleType) {
      return functionUtils.hasSystemRole(roleType, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Save SMTP Project
     */
    saveSMTPProject: function () {
      let data = {
        project_name: this.mediaProject.mediaProjectName,
        page_size: this.mediaProject.mediaPageSize,
        description: this.mediaProject.mediaDescription
      }
      this.PUSH_MEDIA_PROJECT(data)
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Save config SMTP
     */
    validateSMTPConfig: function () {
      let validateResult = 'OK'
      for (let key in this.mediaServer) {
        if (functionUtils.validateStringIsNull(this.mediaServer[key])) {
          validateResult = this.$t('generalConfiguration.' + key)
          break
        }
      }
      return validateResult
    },
    saveConfigSMTP: function () {
      let results = this.validateSMTPConfig()
      if (results === 'OK') {
        let data = {
          media_password: this.mediaServer.mediaPassword,
          media_username: this.mediaServer.mediaUsername,
          media_domain: this.mediaServer.mediaDomain
        }
        this.PUSH_MEDIA_CONFIG(data).then(
          function () {
            this.GET_MEDIA_CONFIG()
            this.getMediaProject()
          }.bind(this)
        )
      } else {
        this.ON_SHOW_TOAST({
          'message': results,
          styleType: ToastType.ERROR
        })
      }
    },
    /**
     * Get SMTP project
     */
    getMediaProject: function () {
      this.is_loading = true
      this.GET_MEDIA_PROJECT()
    },
    ...mapActions([
      'GET_MEDIA_CONFIG',
      'PUSH_MEDIA_CONFIG',
      'GET_MEDIA_PROJECT',
      'PUSH_MEDIA_PROJECT',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>

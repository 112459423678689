<template>
  <v-layout
    v-if="isSuperAdmin() || hasSystemRole(RoleType.ADMIN)"
    wrap>
    <v-flex md12>
      <material-card
        :title="'SMTP'"
        color="green"
        flat
        full-width
      >
        <v-expansion-panel popout>
          <v-expansion-panel-content
            v-for="(item, i) in 3"
            :key="i"
          >
            <template v-slot:header>
              <div v-if="item == 1">
                {{ $t('generalConfiguration.serverSMTP') }}
              </div>
              <div v-else-if="item == 2">
                {{ $t('generalConfiguration.smtpConfigurationInfo') }}
              </div>
              <div v-else>
                {{ $t('generalConfiguration.emailConfiguration') }}
              </div>
            </template>
            <template>
              <div v-if="item == 1">
                <v-container
                  v-if="isSuperAdmin()"
                  grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout
                    >
                      <v-flex
                        xs12
                        sm12
                        md12
                      >
                        <v-text-field
                          :rules="[requiredValue]"
                          v-model="smtp_server.smtpDomain"
                          :label="$t('generalConfiguration.smtpDomain')"
                          class="required"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          :rules="[requiredValue]"
                          v-model="smtp_server.smtpUsername"
                          :label="$t('generalConfiguration.smtpUsername')"
                          class="required"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          :rules="[requiredValue]"
                          v-model="smtp_server.smtpPassword"
                          :label="$t('generalConfiguration.smtpPassword')"
                          type="password"
                          class="required"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6>
                        <v-autocomplete
                          :rules="[requiredValue]"
                          v-model="smtp_server.smtpProjectId"
                          :items="allProjects"
                          :clearable="true"
                          :label="$t('customization.projectDefault')"
                          type="text"
                          class="required"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer/>
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="saveConfigSMTP"
                  >{{ $t('common.save') }}</v-btn>
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="onConnectSMTP"
                  >{{ $t('common.connect') }}</v-btn>
                </v-container>
              </div>
              <div v-else-if="item == 2">
                <v-container grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout
                    >
                      <v-flex
                        v-if="isSuperAdmin()"
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smtp_project.project_name"
                          :label="$t('generalConfiguration.name')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smtp_project.alias"
                          :label="$t('generalConfiguration.alias')"
                        />
                      </v-flex>
                      <v-flex
                        v-if="isSuperAdmin()"
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smtp_project.description"
                          :label="$t('generalConfiguration.description')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smtp_project.page_size"
                          :label="$t('generalConfiguration.pageSize')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-textarea
                          v-model="smtp_project.sub_content"
                          :label="$t('generalConfiguration.subContent')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer/>
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="saveSMTPProject"
                  >{{ $t('common.save') }}</v-btn>
                </v-container>
              </div>
              <div v-else>
                <v-container grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout
                    >
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smtp_email.email_host"
                          :label="$t('generalConfiguration.serverSMTP')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smtp_email.port"
                          :label="$t('generalConfiguration.port')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smtp_email.email_user_host"
                          :label="$t('generalConfiguration.emailAccount')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="smtp_email.password_email"
                          :label="$t('generalConfiguration.emailPassword')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-checkbox
                          :label="$t('generalConfiguration.useTLS')"
                          v-model="smtp_email.email_user_tls"/>
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer/>
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="saveSMTPEmailServer"
                  >{{ $t('common.save') }}</v-btn>
                </v-container>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </material-card>
    </v-flex>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import RoleType from 'enum/roleType'
import ToastType from '@/enum/toastType'
export default {
  components: {
  },
  data: () => ({
    valid: false,
    smtp_email: {
      email_host: null,
      email_user_host: null,
      port: null,
      email_user_tls: true,
      password_email: null
    },
    smtp_project: {
      project_name: null,
      alias: null,
      sub_content: null,
      page_size: null,
      description: null
    },
    smtp_server: {
      smtpDomain: null,
      smtpUsername: null,
      smtpPassword: null,
      smtp_id: 0,
      smtpProjectId: null
    },
    RoleType: {
      ADMIN: RoleType.ADMIN
    },
    entity: {},
    allProjects: []
  }),
  computed: {
    ...mapGetters([
      'GET_SMTP_CONFIG_DATA',
      'GET_SMTP_PROJECT_DATA',
      'GET_CONFIG_SMTP_EMAIL_SERVER_DATA',
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    GET_SMTP_CONFIG_DATA: function () {
      this.smtp_server.smtpDomain = this.GET_SMTP_CONFIG_DATA.smtp_domain ? this.GET_SMTP_CONFIG_DATA.smtp_domain : ''
      this.smtp_server.smtpUsername = this.GET_SMTP_CONFIG_DATA.smtp_username && this.GET_SMTP_CONFIG_DATA.smtp_username
      this.smtp_server.smtpPassword = ''
      this.smtp_server.smtp_id = this.GET_SMTP_CONFIG_DATA.smtp_id && this.GET_SMTP_CONFIG_DATA.smtp_id
      this.smtp_server.smtpProjectId = this.GET_SMTP_CONFIG_DATA && this.GET_SMTP_CONFIG_DATA.smtp_project_id
    },
    GET_SMTP_PROJECT_DATA: function () {
      this.smtp_project.project_name = this.GET_SMTP_PROJECT_DATA.project_name && this.GET_SMTP_PROJECT_DATA.project_name
      this.smtp_project.alias = this.GET_SMTP_PROJECT_DATA.alias && this.GET_SMTP_PROJECT_DATA.alias
      this.smtp_project.sub_content = this.GET_SMTP_PROJECT_DATA.tail_content && this.GET_SMTP_PROJECT_DATA.tail_content
      this.smtp_project.page_size = this.GET_SMTP_PROJECT_DATA.pagination && this.GET_SMTP_PROJECT_DATA.pagination
      this.smtp_project.description = this.GET_SMTP_PROJECT_DATA.description && this.GET_SMTP_PROJECT_DATA.description
    },
    GET_CONFIG_SMTP_EMAIL_SERVER_DATA: function () {
      this.smtp_email.email_host = this.GET_CONFIG_SMTP_EMAIL_SERVER_DATA.email_host && this.GET_CONFIG_SMTP_EMAIL_SERVER_DATA.email_host
      this.smtp_email.email_user_host = this.GET_CONFIG_SMTP_EMAIL_SERVER_DATA.email_user_host && this.GET_CONFIG_SMTP_EMAIL_SERVER_DATA.email_user_host
      this.smtp_email.port = this.GET_CONFIG_SMTP_EMAIL_SERVER_DATA.port && this.GET_CONFIG_SMTP_EMAIL_SERVER_DATA.port
      this.smtp_email.email_user_tls = this.GET_CONFIG_SMTP_EMAIL_SERVER_DATA.email_user_tls && this.GET_CONFIG_SMTP_EMAIL_SERVER_DATA.email_user_tls
      this.smtp_email.password_email = null
    }
  },
  created () {
    this.GET_SMTP_CONFIG()
    this.getSMTPProject()
    this.getSMTPEmailServer()
    // Get all smtp project
    let filter = {
      params: {
        isGetAll: true
      }
    }
    this.GET_ALL_SMTP_PROJECT(filter).then(
      function (res) {
        let data = res.data
        this.allProjects = []
        for (let i = 0, size = data.length; i < size; i++) {
          let obj = {
            value: data[i].id,
            text: data[i].project_name
          }
          this.allProjects.push(obj)
        }
      }.bind(this)
    )
  },
  methods: {
    /**
     * Connect SMTP
     */
    onConnectSMTP: function () {
      let filter = {
        smtp_domain: this.smtp_server.smtpDomain,
        smtp_username: this.smtp_server.smtpUsername,
        smtp_password: this.smtp_server.smtpPassword,
        project_id: this.smtp_server.smtpProjectId
      }
      this.CONNECT_SMTP_CHECK_PROJECT(filter).then(
        function (res) {
          let data = res.data
          if (!functionUtils.isNull(data.message) && data.message === 'not_found_project') {
            this.ON_SHOW_TOAST({
              'message': this.$t('customization.errorMessage.notFoundProject'),
              styleType: ToastType.ERROR
            })
          } else if (!functionUtils.isNull(data.message) && data.message === 'project_id_is_none') {
            this.ON_SHOW_TOAST({
              'message': this.$t('customization.errorMessage.projectIdIsNone'),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
          }
        }.bind(this)
      ).catch(
        function (error) {
          let messageError = error.response.data.message
          if (!functionUtils.isNull(messageError) && messageError === 'error_login') {
            this.ON_SHOW_TOAST({
              'message': this.$t('customization.errorMessage.infoConnectIncorrect'),
              styleType: ToastType.ERROR
            })
          } else if (!functionUtils.isNull(messageError) && messageError === 'error_connection') {
            this.ON_SHOW_TOAST({
              'message': this.$t('customization.errorMessage.cannotConnectToServer'),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    // Required value
    requiredValue (value) {
      if (!functionUtils.isNull(value) && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    /**
     * Is entity type
     */
    isEntityType: function (entityType) {
      return functionUtils.isEntityType(entityType)
    },
    /**
     * Has system role
     */
    hasSystemRole: function (roleType) {
      return functionUtils.hasSystemRole(roleType, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Save SMTP Server
     */
    saveSMTPEmailServer: function () {
      let data = {
        email_host: this.smtp_email.email_host,
        email_user_host: this.smtp_email.email_user_host,
        password_email: this.smtp_email.password_email,
        email_user_tls: this.smtp_email.email_user_tls,
        port: this.smtp_email.port,
        project_id: this.smtp_server.smtpProjectId
      }
      this.PUSH_CONFIG_SMTP_EMAIL_SERVER(data)
    },
    /**
     * Save SMTP Project
     */
    saveSMTPProject: function () {
      let data = {
        project_name: this.smtp_project.project_name,
        alias: this.smtp_project.alias,
        page_size: this.smtp_project.page_size,
        sub_content: this.smtp_project.sub_content,
        description: this.smtp_project.description,
        project_id: this.smtp_server.smtpProjectId
      }
      this.PUSH_SMTP_PROJECT(data)
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Save config SMTP
     */
    validateSMTPConfig: function () {
      let validateResult = 'OK'
      for (let key in this.smtp_server) {
        if (key === 'smtpProjectId' ||
          key === 'smtp_id') {
          //
        } else if (functionUtils.validateStringIsNull(this.smtp_server[key])) {
          validateResult = this.$t('generalConfiguration.' + key)
          break
        }
      }
      return validateResult
    },
    saveConfigSMTP: function () {
      let results = this.validateSMTPConfig()
      if (results === 'OK') {
        let data = {
          smtp_password: this.smtp_server.smtpPassword,
          smtp_username: this.smtp_server.smtpUsername,
          smtp_domain: this.smtp_server.smtpDomain,
          smtp_project_id: this.smtp_server.smtpProjectId
        }
        this.PUSH_SMTP_CONFIG(data).then(
          function () {
            this.GET_SMTP_CONFIG()
            this.getSMTPProject()
            this.getSMTPEmailServer()
          }.bind(this)
        )
      } else {
        this.ON_SHOW_TOAST({
          'message': results,
          styleType: ToastType.ERROR
        })
      }
    },
    /**
     * Get SMTP Email server
     */
    getSMTPEmailServer: function () {
      this.GET_CONFIG_SMTP_EMAIL_SERVER()
    },
    /**
     * Get SMTP project
     */
    getSMTPProject: function () {
      this.is_loading = true
      this.GET_SMTP_PROJECT()
    },
    ...mapActions([
      'GET_SMTP_CONFIG',
      'PUSH_SMTP_CONFIG',
      'GET_SMTP_PROJECT',
      'PUSH_SMTP_PROJECT',
      'GET_CONFIG_SMTP_EMAIL_SERVER',
      'PUSH_CONFIG_SMTP_EMAIL_SERVER',
      'ON_SHOW_TOAST',
      'UPDATE_ENTITY',
      'GET_ALL_SMTP_PROJECT',
      'CONNECT_SMTP_CHECK_PROJECT'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
